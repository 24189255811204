<template>
    <Layout>
        <TransactionManagementTabs v-if="!$route.query.window" />
        <div class="card data">
            <div class="card-header bg-light bg-soft filters">
                <b-form inline>
                    <table
                        class="table table-bordered table-nowrap mb-0 table-form">
                        <tbody>
                            <tr>
                                <td class="text-center hidden">
                                    {{ $t('date') }}
                                </td>
                                <td class="bg-white">
                                    <div class="d-flex gap-1">
                                        <datepicker
                                            :disabled="
                                                Boolean($route.query.window)
                                            "
                                            v-model="filters.from"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>

                                        <label class="input-group-text"
                                            >~</label
                                        >
                                        <datepicker
                                            :disabled="
                                                Boolean($route.query.window)
                                            "
                                            v-model="filters.to"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>
                                        <button
                                            class="btn btn-outline-info flex-grow-1"
                                            @click="setDay('today')">
                                            {{ $t('today') }}
                                        </button>
                                        <button
                                            class="btn btn-outline-success flex-grow-1"
                                            @click="setDay('yesterday')">
                                            {{ $t('yesterday') }}
                                        </button>
                                    </div>
                                </td>

                                <!-- <td class="text-center">
                                            {{ $t('username') }}
                                        </td>
                                        <td class="bg-white">
                                            <input
                                                :readonly="Boolean($route.query.window)"
                                                type="text"
                                                class="form-control"
                                                :placeholder="
                                                        $t(`enter exact username`)
                                                    "
                                                v-model="filters.username"
                                            />
                                        </td> -->
                                <td class="text-center hidden">
                                    {{ $t('status') }}
                                </td>
                                <td class="bg-white">
                                    <multiselect
                                        :placeholder="`${$t('select status')}`"
                                        v-model="filters.status"
                                        :options="status_options">
                                        <template
                                            v-slot:singlelabel="{ value }">
                                            <span class="flex-fill ps-2">{{
                                                $t(value.value)
                                            }}</span>
                                        </template>
                                        <template v-slot:option="{ option }">
                                            {{ $t(option.value) }}
                                        </template>
                                    </multiselect>
                                </td>
                                <td class="text-center hidden">
                                    {{ $t('search') }}
                                </td>
                                <td class="bg-white">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`enter keyword`)"
                                        v-model="filters.keyword" />
                                </td>
                                <td
                                    class="bg-white text-center"
                                    v-if="!$route.query.window">
                                    <div class="button-items">
                                        <button
                                            :class="`btn btn-danger ${
                                                loading ? 'disabled' : ''
                                            }`"
                                            @click="resetFilter()">
                                            {{ $t('reset') }}
                                        </button>
                                        <b-dropdown
                                            id="dropdown-1"
                                            :text="$t('change')"
                                            :variant="
                                                selected_transactions.length < 1
                                                    ? 'light'
                                                    : 'warning'
                                            "
                                            :disabled="
                                                selected_transactions.length < 1
                                            ">
                                            <b-dropdown-item
                                                @click="
                                                    batchUpdateStatus('review')
                                                "
                                                ><span class="text-info">{{
                                                    $t(`review`)
                                                }}</span></b-dropdown-item
                                            >
                                            <b-dropdown-item
                                                @click="
                                                    batchUpdateStatus(
                                                        'complete'
                                                    )
                                                "
                                                ><span class="text-success">{{
                                                    $t(`complete`)
                                                }}</span></b-dropdown-item
                                            >
                                            <b-dropdown-item
                                                @click="
                                                    batchUpdateStatus('cancel')
                                                "
                                                ><span class="text-danger">{{
                                                    $t(`cancel`)
                                                }}</span></b-dropdown-item
                                            >
                                        </b-dropdown>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <LoadingTable
                        :cols="2"
                        :rows="1"
                        :hide_thead="true"
                        class="table-bordered bg-white mb-0"
                        v-if="total_loading" />
                    <table v-else class="table table-bordered mb-0 table-grid">
                        <tbody>
                            <tr class="bg-white">
                                <td>
                                    <div class="text-muted">
                                        {{ $t('total deposit') }}
                                    </div>
                                    <div class="text-end fw-bold">
                                        <span
                                            >{{
                                                numberString(
                                                    total.total_deposit_amount
                                                )
                                            }}
                                            {{ $t('KRW') }}</span
                                        >
                                        ({{
                                            numberString(
                                                total.total_deposit_count
                                            )
                                        }})
                                    </div>
                                </td>
                                <td>
                                    <div class="text-muted">
                                        {{ $t('total withdraw') }}
                                    </div>
                                    <div class="text-end fw-bold">
                                        <span
                                            >{{
                                                numberString(
                                                    total.total_withdraw_amount
                                                )
                                            }}
                                            {{ $t('KRW') }}</span
                                        >
                                        ({{
                                            numberString(
                                                total.total_withdraw_count
                                            )
                                        }})
                                    </div>
                                </td>
                                <td>
                                    <div class="text-muted">
                                        {{ $t('deposit-withdrawal') }}
                                    </div>
                                    <div class="text-end fw-bold">
                                        <span
                                            >{{
                                                numberString(
                                                    total.total_difference
                                                )
                                            }}
                                            {{ $t('KRW') }}</span
                                        >
                                    </div>
                                </td>
                                <td
                                    class="bg-light bg-soft"
                                    style="width: 120px">
                                    <multiselect
                                        style="width: 120px"
                                        class="multiselect-sm me-0"
                                        :placeholder="`${$t('limit')}`"
                                        v-model="filters.limit"
                                        :options="[50, 100, 500, 1000]"
                                        :preselect-first="true"
                                        :can-deselect="false"
                                        :can-clear="false">
                                        <template
                                            v-slot:singlelabel="{ value }">
                                            <span class="mx-auto">{{
                                                $t('view') + ' ' + value.value
                                            }}</span>
                                        </template>
                                    </multiselect>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-form>
            </div>
            <div class="card-body">
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="8"
                        :rows="50"
                        :td_small_class="`d-none`"
                        class="table-bordered table-striped"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list">
                        <thead class="hidden">
                            <tr>
                                <th scope="col">
                                    <div class="form-check-dark">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            @input="addAllTransactioId" />
                                    </div>
                                </th>
                                <th scope="col">
                                    {{ $t('username') }}
                                </th>
                                <th
                                    scope="col"
                                    v-if="
                                        $route.params.transaction == 'withdraw'
                                    ">
                                    {{ $t('previous balance') }}
                                </th>
                                <th scope="col">
                                    {{
                                        $t(
                                            $route.params.transaction ==
                                                'deposit'
                                                ? 'charging amount'
                                                : 'exchange amount'
                                        )
                                    }}
                                </th>
                                <th
                                    scope="col"
                                    v-if="
                                        $route.params.transaction == 'withdraw'
                                    ">
                                    {{ $t('amount after exchange') }}
                                </th>
                                <th scope="col">
                                    {{ $t('physical bank') }}
                                </th>
                                <!-- <th scope="col">
                                    {{ $t('virtual bank') }}
                                </th> -->
                                <th scope="col">
                                    {{ $t('application date') }}
                                </th>
                                <th scope="col">
                                    {{ $t('processing date') }}
                                </th>
                                <th scope="col">
                                    {{ $t('referrer') }}
                                </th>
                                <th scope="col">
                                    {{ $t('status') }}
                                </th>
                                <th scope="col">
                                    {{ $t('management') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="r in data.list"
                                :key="r._id"
                                :class="{
                                    selected: transaction_ids.includes(r._id),
                                }">
                                <td class="text-center">
                                    <div class="form-check-dark">
                                        <input
                                            :checked="
                                                transaction_ids.includes(r._id)
                                            "
                                            :disabled="
                                                ['complete', 'cancel'].includes(
                                                    r.status
                                                )
                                            "
                                            class="form-check-input"
                                            type="checkbox"
                                            :value="r._id"
                                            :id="`row-${r._id}`"
                                            @input="addTransactionId" />
                                    </div>
                                    <!-- {{ i + 1 + (data.current_page - 1) * data.per_page }} -->
                                </td>
                                <td>
                                    <div class="td-username">
                                        <a
                                            href="javascript:void(0)"
                                            class="text-secondary"
                                            @click="
                                                goToTicketForm(
                                                    r.username,
                                                    'player'
                                                )
                                            ">
                                            <i
                                                class="bx bx-envelope bx-tada-hover fs-4"></i
                                        ></a>
                                        <a
                                            :title="$t(`click to view`)"
                                            href="javascript:void(0)"
                                            @click="
                                                goToUserForm(
                                                    r.user_id,
                                                    'player'
                                                )
                                            "
                                            class="link-username"
                                            ><i
                                                :class="`mdi mdi-numeric-${
                                                    r.user.player.level || 0
                                                }-box fs-5 level-${
                                                    r.user.player.level || 0
                                                }`">
                                            </i>
                                            {{ r.user.username }}
                                        </a>
                                    </div>
                                </td>
                                <td
                                    class="text-end"
                                    v-if="
                                        $route.params.transaction == 'withdraw'
                                    ">
                                    {{
                                        numberString(
                                            r.first_asset_log.initial_value
                                        )
                                    }}
                                </td>
                                <td
                                    class="text-end"
                                    :class="{
                                        'text-primary fw-bold':
                                            $route.params.transaction ==
                                            'withdraw',
                                    }">
                                    {{ numberString(r.amount) }}
                                </td>
                                <td
                                    class="text-end"
                                    v-if="
                                        $route.params.transaction == 'withdraw'
                                    ">
                                    {{
                                        numberString(
                                            r.first_asset_log.updated_value
                                        )
                                    }}
                                </td>
                                <td class="text-center">
                                    {{
                                        r.handler_bank.bank_name
                                            ? r.handler_bank.bank_name + '/'
                                            : ''
                                    }}
                                    {{
                                        r.handler_bank.account_number
                                            ? r.handler_bank.account_number
                                            : ''
                                    }}
                                    {{
                                        r.handler_bank.account_holder
                                            ? '/' +
                                              r.handler_bank.account_holder
                                            : ''
                                    }}
                                </td>
                                <!-- <td class="text-center">
                                    {{
                                        r.customer_bank.bank_name
                                            ? r.customer_bank.bank_name + '/'
                                            : ''
                                    }}
                                    {{
                                        r.customer_bank.account_number
                                            ? r.customer_bank.account_number
                                            : ''
                                    }}
                                    {{
                                        r.customer_bank.account_holder
                                            ? '/' +
                                              r.customer_bank.account_holder
                                            : ''
                                    }}
                                </td> -->

                                <td class="text-center">
                                    {{ dateYmD(r.created_at, true) }}
                                </td>
                                <td class="text-center">
                                    {{ dateYmD(r.updated_at, true) }}
                                </td>
                                <td></td>
                                <td class="text-center">
                                    <span
                                        :class="{
                                            'text-warning':
                                                r.status == 'pending',
                                            'text-info': r.status == 'review',
                                            'text-success fw-bold':
                                                r.status == 'complete',
                                            'text-danger': r.status == 'cancel',
                                        }"
                                        >{{ $t(r.status) }}</span
                                    >
                                </td>
                                <td class="text-center">
                                    <div class="button-items">
                                        <button
                                            v-if="
                                                ['pending'].includes(r.status)
                                            "
                                            @click="updateStatus(r, 'review')"
                                            class="btn btn-outline-info btn-sm">
                                            {{ $t('review') }}
                                        </button>
                                        <button
                                            v-if="
                                                ![
                                                    'complete',
                                                    'cancel',
                                                ].includes(r.status)
                                            "
                                            @click="updateStatus(r, 'complete')"
                                            class="btn btn-outline-success btn-sm">
                                            {{ $t('complete') }}
                                        </button>
                                        <button
                                            v-if="
                                                ['pending', 'review'].includes(
                                                    r.status
                                                )
                                            "
                                            @click="updateStatus(r, 'cancel')"
                                            class="btn btn-outline-danger btn-sm">
                                            {{ $t('cancel') }}
                                        </button>
                                    </div>
                                </td>
                                <td class="mobile">
                                    <div class="cell-card">
                                        <div class="form-check-dark">
                                            <input
                                                :disabled="
                                                    [
                                                        'complete',
                                                        'cancel',
                                                    ].includes(r.status)
                                                "
                                                class="form-check-input"
                                                type="checkbox"
                                                :value="r._id"
                                                :id="`row-${r._id}`"
                                                @input="addTransactionId" />
                                        </div>
                                        <div class="td-username">
                                            <a
                                                href="javascript:void(0)"
                                                class="text-secondary"
                                                @click="
                                                    goToTicketForm(
                                                        r.username,
                                                        'player'
                                                    )
                                                ">
                                                <i
                                                    class="bx bx-envelope bx-tada-hover fs-4"></i
                                            ></a>

                                            <a
                                                :title="$t(`click to view`)"
                                                href="javascript:void(0)"
                                                @click="
                                                    goToUserForm(
                                                        r.user_id,
                                                        'player'
                                                    )
                                                "
                                                class="link-username"
                                                ><i
                                                    :class="`mdi mdi-numeric-${
                                                        r.user.player.level || 0
                                                    }-box fs-5 level-${
                                                        r.user.player.level || 0
                                                    }`">
                                                </i>
                                                {{ r.user.username }}
                                            </a>
                                        </div>
                                        <div>
                                            <div>
                                                {{ numberString(r.amount) }}
                                            </div>
                                            <div class="text-muted">
                                                {{
                                                    dateYmD(r.created_at, true)
                                                }}
                                            </div>
                                            <div
                                                :class="{
                                                    'text-warning':
                                                        r.status == 'pending',
                                                    'text-info':
                                                        r.status == 'review',
                                                    'text-success fw-bold':
                                                        r.status == 'complete',
                                                    'text-danger':
                                                        r.status == 'cancel',
                                                }">
                                                {{ $t(r.status) }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="no-data-placeholder"
                        v-show="data.list.length < 1">
                        {{ $t('no data found') }}
                    </div>
                </div>
                <Pagination :data="data" @emitPage="initList" />
            </div>
        </div>
        <b-modal
            hide-header-close
            no-close-on-backdrop
            v-model="batch_update_modal"
            centered
            hide-footer
            :title="$t(batch_action)"
            body-class="pt-0"
            @hidden="reset()">
            <div
                class="table-responsive mb-3 border"
                style="max-height: 300px; min-height: 300px">
                <table
                    class="table table-bordered table-hover table-striped table-sm">
                    <thead>
                        <tr>
                            <th>{{ $t('application date') }}</th>
                            <th>{{ $t('username') }}</th>
                            <th>{{ $t('amount') }}</th>
                            <th>{{ $t('status') }}</th>
                            <th>{{ $t('processing') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="r in selected_transactions" :key="r._id">
                            <td class="text-center">
                                {{ dateYmD(r.created_at, true) }}
                            </td>
                            <td class="text-center">{{ r.username }}</td>
                            <td class="text-end">
                                {{ numberString(r.amount) }}
                            </td>
                            <td class="text-center">
                                <span
                                    :class="{
                                        'text-warning': r.status == 'pending',
                                        'text-info': r.status == 'review',
                                        'text-success fw-bold':
                                            r.status == 'complete',
                                        'text-danger': r.status == 'cancel',
                                    }"
                                    :id="`status-${r._id}`"
                                    >{{ $t(r.status) }}</span
                                >
                            </td>
                            <td class="text-center">
                                <i
                                    class="font-size-18 bx"
                                    :id="`progress-${r._id}`"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-center gap-1">
                <button
                    v-if="batch_updated == true"
                    type="button"
                    class="btn btn-primary"
                    @click="closeBatchUpdateModal">
                    {{ $t('ok') }}
                </button>
                <button
                    v-if="batch_updated == false"
                    :disabled="batch_updating"
                    type="button"
                    class="btn btn-warning"
                    @click="startInterval()">
                    {{ $t('confirm') }}
                </button>
                <button
                    v-if="batch_updated == false"
                    :disabled="batch_updating"
                    type="button"
                    class="btn btn-secondary"
                    @click="batch_update_modal = false">
                    {{ $t('cancel') }}
                </button>
            </div>
        </b-modal>
    </Layout>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../../components/pagination.vue';
import TransactionManagementTabs from '@/components/transaction-management-tabs';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';
import convert from '../../../../mixins/convert';
import datetime from '../../../../mixins/datetime';
import number from '../../../../mixins/number';
import ui from '../../../../mixins/ui';
export default {
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
        TransactionManagementTabs,
    },
    mixins: [datetime, number, convert, ui],
    data() {
        return {
            test: null,
            title: `players`,
            filters: {
                limit: 50,
                keyword: null,
                username: null,
                status: null,
                from: new Date(this.firstDateOftheMonth()),
                to: new Date(),
            },
            status_options: [
                { name: this.$t('pending'), value: 'pending' },
                { name: this.$t('review'), value: 'review' },
                { name: this.$t('completed'), value: 'complete' },
                { name: this.$t('cancelled'), value: 'cancel' },
            ],
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            total: {
                total_deposit_count: 0,
                total_deposit_amount: 0,
                total_withdraw_count: 0,
                total_withdraw_amount: 0,
                total_difference: 0,
            },
            total_loading: false,
            batch_action: '',
            transaction_ids: [],
            create_modal: false,
            loading: false,
            polling: null,
            loopIndex: 0,
            batch_update_modal: false,
            batch_updating: false,
            batch_updated: false,
        };
    },
    computed: {
        selected_transactions() {
            var arr = [];
            this.data.list.forEach((t) => {
                if (this.transaction_ids.includes(t._id)) {
                    arr.push(t);
                    // if (
                    //     (['complete', 'cancel'].includes(this.batch_action) &&
                    //         ['review', 'pending'].includes(t.status)) ||
                    //     (['review'].includes(this.batch_action) &&
                    //         t.status == 'pending')
                    // ) {

                    // }
                }
            });
            return arr;
        },
    },
    watch: {
        filters: {
            handler() {
                this.initList(1);
                this.initListTotal();
            },
            deep: true,
        },
        // batch_updated(isFinish) {
        //     if (isFinish) {
        //         this.initList(1);
        //         this.initListTotal();
        //     }
        // },
        '$store.state.key'() {
            this.initList(1);
            this.initListTotal();
        },
    },
    methods: {
        ...mapActions('auth', {
            authGetProfile: 'getProfile',
        }),
        ...mapActions('transaction', {
            transactionGetList: 'getList',
            transactionGetListTotal: 'getListTotal',
            transactionUpdate: 'update',
        }),
        saveQuery() {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('page', this.data.current_page);
            if (this.filters.status != null) {
                urlParams.set('status', this.filters.status);
            } else {
                urlParams.delete('status');
            }
            window.history.pushState(
                {},
                '',
                `${window.location.pathname}?${urlParams.toString()}`
            );
        },
        readQuery() {
            this.filters.status = this.$route.query.status || null;
            if (this.$route.query.username)
                this.filters.username = this.$route.query.username;
            if (this.$route.query.from)
                this.filters.from = new Date(this.$route.query.from);
            if (this.$route.query.to)
                this.filters.to = new Date(this.$route.query.to);
        },
        async initList(p) {
            if (!this.batch_update_modal) {
                this.transaction_ids = [];
            }
            var pl = {
                user: 'player',
                online: this.$route.params.type == 'online',
                type: this.$route.params.transaction,
                limit: this.filters.limit,
                search: this.filters.keyword,
                username: this.filters.username,
                status: this.filters.status,
                from: this.dateYmD(this.filters.from) + ' 00:00:00',
                to: this.dateYmD(this.filters.to) + ' 23:59:59',
                page_no: p ? p : 1,
            };

            this.loading = true;
            const data = await this.transactionGetList(pl);
            this.data.list = data.data;
            this.data.links = data.links;
            this.data.current_page = data.current_page;
            this.data.per_page = data.per_page;
            this.data.last_page = data.last_page;
            this.data.total = data.total;
            this.loading = false;
        },
        async initListTotal(p) {
            var pl = {
                user: 'player',
                type: this.$route.params.transaction,
                online: this.$route.params.type == 'online',
                search: this.filters.keyword,
                username: this.filters.username,
                status: this.filters.status,
                from: this.dateYmD(this.filters.from) + ' 00:00:00',
                to: this.dateYmD(this.filters.to) + ' 23:59:59',
            };

            this.total_loading = true;
            const data = await this.transactionGetListTotal(pl);
            this.total.total_deposit_count = data.total_deposit_count;
            this.total.total_deposit_amount = data.total_deposit_amount;
            this.total.total_withdraw_count = data.total_withdraw_count;
            this.total.total_withdraw_amount = data.total_withdraw_amount;
            this.total.total_difference = data.total_difference;
            this.total_loading = false;
        },
        resetFilter() {
            this.filters.keyword = null;
            this.filters.username = null;
            this.filters.status = '';
            this.filters.from = new Date(this.firstDateOftheMonth());
            this.filters.to = new Date();
            this.initList(1);
        },
        addTransactionId(e) {
            var id = e.target.value;
            if (e.target.checked) {
                this.transaction_ids.push(id);
            } else {
                var index = this.transaction_ids.indexOf(id);
                this.transaction_ids.splice(index, 1);
            }
        },
        addAllTransactioId(e) {
            if (e) {
                this.data.list.forEach((transaction) => {
                    var transaction = document.getElementById(
                        `row-${transaction._id}`
                    );
                    if (e.target.checked != transaction.checked) {
                        transaction.click();
                    }
                });
            } else {
                this.data.list.forEach((transaction) => {
                    var transaction = document.getElementById(
                        `row-${transaction._id}`
                    );
                    if (transaction.checked) {
                        transaction.click();
                    }
                });
            }
        },
        async updateStatus(r, status) {
            if (!this.isAuthorized()) return;
            var swal_html = `
                        <table class="table table-sm table-bordered fs-6 mt-1">
                            <tbody>
                                    <tr>
                                    <th>${this.$t('username')}</th>
                                    <td>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <i class="mdi mdi-numeric-${
                                                r.user.player.level
                                            }-box fs-4  level-${
                r.user.player.level
            }"></i>
                                            ${r.user.username}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>${this.$t('transaction')}</th>
                                    <td>
                                      ${this.$t(this.$route.params.transaction)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>${this.$t('amount')}</th>
                                    <td>
                                       ${this.numberString(r.amount)}
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    `;
            const confirmed = await Swal.fire({
                title: this.$t(status),
                html: swal_html,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                var fd = new FormData();
                fd.append('status', status);
                const res = await this.transactionUpdate({
                    user: 'player',
                    id: r._id,
                    form_data: this.formDataToUrlEncoded(fd),
                });

                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t('the transaction has been updated')
                            : this.$t('an error occured'),
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                        this.authGetProfile();
                        this.$store.commit('removeNotificationById', r._id);
                    }
                }
            }
        },
        async batchUpdateStatus(status) {
            this.batch_action = status;
            this.batch_update_modal = true;
        },
        async startInterval() {
            if (!this.isAuthorized()) return;
            this.batch_updating = true;
            var vm = this;
            for (let i = 0; i < this.selected_transactions.length; i++) {
                await vm.loop(i);
            }
            this.batch_updated = true;
        },
        async loop(i) {
            var transaction = this.selected_transactions[i];
            var progress = document.getElementById(
                `progress-${transaction._id}`
            );
            var status = document.getElementById(`status-${transaction._id}`);

            if (progress)
                progress.classList.add('bx-loader', 'bx-spin', 'text-dark');

            var fd = new FormData();
            fd.append('status', this.batch_action);
            const res = await this.transactionUpdate({
                user: 'player',
                id: transaction._id,
                form_data: this.formDataToUrlEncoded(fd),
            });
            progress.classList.remove('bx-loader', 'bx-spin', 'text-dark');
            if (res.success) {
                this.$store.commit('removeNotificationById', transaction._id);
                progress.classList.add('bxs-check-circle', 'text-success');
                status.className = '';
                status.classList.add(
                    this.batch_action == 'review'
                        ? 'text-info'
                        : this.batch_action == 'complete'
                        ? 'text-success'
                        : this.batch_action == 'cancel'
                        ? 'text-danger'
                        : ''
                );
                status.innerHTML = this.$t(this.batch_action);
            } else {
                progress.classList.add('bxs-x-circle', 'text-danger');
            }
        },
        closeBatchUpdateModal() {
            this.batch_update_modal = false;
            this.authGetProfile();
            this.initList(1);
            this.initListTotal();
        },
        setDay(mode) {
            if (mode == 'today') {
                this.filters.from = new Date();
                this.filters.to = new Date();
            } else {
                this.filters.from = this.addDaysToDate(-1);
                this.filters.to = this.addDaysToDate(-1);
            }
        },
        reset() {
            this.polling = null;
            this.loopIndex = 0;
            this.batch_update_modal = false;
            this.batch_updating = false;
            this.batch_updated = false;
        },
        handleKeyPress(event) {
            // Trigger the confirm dialog on spacebar press
            if (this.batch_update_modal && !this.batch_updated) {
                if (event.key === ' ' || event.key === 'Spacebar') {
                    event.preventDefault(); // Prevent scrolling the page
                    this.startInterval();
                }
            }
        },
    },
    mounted() {
        if (this.$route.query.window) {
            this.readQuery();
        } else {
            this.initList(1);
            this.initListTotal();
        }
        this.$watch(
            () => this.$route.params,
            (n, o) => {
                if (this.$route.query.window) {
                    this.readQuery();
                } else {
                    if (this.$route.name == 'player-transaction') {
                        this.initList(1);
                        this.initListTotal();
                    }
                }
            }
        );

        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.bind('new-deposit', (e) => {
                if (
                    e.message.role === 'player' &&
                    this.$route.params.transaction == 'deposit'
                ) {
                    this.resetFilter();
                }
            });
            window.PusherChannel.bind('new-withdraw', (e) => {
                if (
                    e.message.role === 'player' &&
                    this.$route.params.transaction == 'withdraw'
                ) {
                    this.resetFilter();
                }
            });
        }
        // swal hotkeys
        window.addEventListener('keydown', this.handleKeyPress);
    },
    beforeUnmount() {
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.unbind('new-deposit');
            window.PusherChannel.unbind('new-withdraw');
        }
    },
};
</script>
